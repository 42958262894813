import styled from "@emotion/styled";
import { BtnGotIt } from "assets/images/new";

export const BtnGotItContainer = styled.div`
  width: 150px;
  height: 56.5px;
  background-color: #b414e2;
  background-image: url(${BtnGotIt});
  background-size: 100%;
  border-radius: 6px;
  #blockAnim {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: #b414e2; */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #textOverlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 96%;
    height: 88%;
    line-height: initial;
    color: #fff;
    font-family: "Impact", sans-serif;
    font-size: 28px;
    text-align: center;
    border-radius: 10px;
    z-index: 10;
    text-shadow: 3px 5px 5px purple;
    -moz-box-shadow: 0px 0px 25px 20px purple inset;
    -webkit-box-shadow: 0px 0px 25px 20px purple inset;
    box-shadow: 0px 0px 25px 20px purple inset;
    -webkit-animation: glow 0.5s ease-in-out infinite alternate;
    -moz-animation: glow 0.5s ease-in-out infinite alternate;
    animation: glow 0.5s ease-in-out infinite alternate;
  }

  .d {
    height: 100%;
  }
  canvas {
    /* margin-top: -170px; */
  }

  @keyframes glow {
    from {
      box-shadow: 0 0px 40px 10px #b817e6 inset;
    }
    to {
      box-shadow: 0 0px 90px -10px purple inset;
    }
  }
`;
